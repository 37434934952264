import React from 'react';
import ad from '../images/clients/AD.png';
import sw from '../images/clients/SW.png';
import sen from '../images/clients/sensormatic.svg';

const clientImage = {
    width: 'auto',
    mixBlendMode: 'colorBurn'
}

const Clients = () => {
    return (
        <div className="mt-16 bg-gray-100">
            <section data-aos="fade-up">
                <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Our Suppliers</h2>
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-blue-900'></div>
                        </div>
                        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">Some of our Suppliers.</h2>
                    </div>

                <div className="p-5" data-aos="fade-in" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-2  ">
                        <div style={clientImage} className= "overflow-hidden flex justify-center transition-all ease-in-out opacity-80 hover:opacity-100 w-1/6">
                            <a href='https://www.americandynamics.net/'><img src={ad} alt="Supplier" />    </a>                       
                        </div>

                       

                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-80 hover:opacity-100">
                            <a href='https://www.swhouse.com/'><img src={sw} alt="Supplier" />  </a>                          
                        </div>

                        {/* <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-80 hover:opacity-100">
                            <img src={sen} alt="Supplier" />                            
                        </div>               */}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Clients;